<template>
  <div class="helper">
    <v-img
      contain
      :src="`/images/bg_new${imgBg}.svg`"
      max-width="1043"
      class="img-help"
    ></v-img>

    <div class="data-help text-center py-1 px-2 align-center" style="width: 100%">
      <v-row no-gutters class="px-2 display-flex justify-center justify-md-start">
        <v-col cols="12" md="6" lg="4" class="text-center text-md-left pl-2 mb-2 mb-md-0">
          Copyright © {{ new Date().getFullYear() }} <a href="https://damudo.at" target="_blank">Damudo</a>, All rights
          Reserved
        </v-col>
        <v-col cols="12" md="6" lg="8" class="text-right text-sm-center">
          <v-row no-gutters class="justify-center justify-md-end">
            <div class="mb-2 mb-md-0 mr-1">
              User: <span class="color-primary">{{ user ? user.name + ',' : '' }}</span>
            </div>
            <div class="mb-2 mb-md-0">
              BRANCH: <span class="color-primary">{{ backEnd.branch }}</span>, COMMIT: <span
                class="color-primary"
              >{{ backEnd.commit.id }}</span>, TIME: <span
                class="color-primary"
              >{{ backEnd.commit.time | formatDateTimePrecise }}</span>
            </div>
            <div v-if="$vuetify.breakpoint.lgAndUp" class="px-2"> | </div>
            <div class="">
              {{ $lang.labels.frontEnd }}: <span class="color-primary">{{ frontEnd }}</span>, TIME: <span class="color-primary">{{ frontEndBuildTime | formatDateTimePrecise }} UTC</span>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  data() {
    return {
      user: localStorage.userData ? JSON.parse(localStorage.userData) : null,
      frontEnd: '',
      frontEndBuildTime: '',
      backEnd: {
        branch: '',
        commit: {
          id: '',
          time: ''
        }
      }
    }
  },
  computed: {
    imgBg() {
      const min = Math.ceil(1)
      const max = Math.floor(3)

      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  },
  mounted() {

    this.frontEnd = localStorage.frontendVersion ? localStorage.frontendVersion : ''

    this.frontEndBuildTime = localStorage.frontendBuildtime ? localStorage.frontendBuildtime : ''

    this.backEnd = localStorage.backendVersion ? JSON.parse(localStorage.backendVersion) : {
      branch: '',
      commit: {
        id: '',
        time: ''
      }
    }
  }
}
</script>
<style lang="scss">
.helper {
  position: relative;
  width: 100%;
  height: 100%;
}

.img-help {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.data-help {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
